import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap';
import termsAndConditions from '../../resources/Actify_TermsandConditions';
import dataCollectionPolicy from '../../resources/Actify_DataCollectionPolicy';
import cookiesPolicy from '../../resources/Actify_CookiesPolicy';
import logo from '../../resources/img/actify_logo_small.png';
import './Footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
      showDataCollection: false,
      showCookiesPolicy: false,
    };
  }

  render() {
    const { paddingZero, pushHistory } = this.props;
    let showTerms = this.state.showTerms;
    let showDataCollection = this.state.showDataCollection;
    let showCookiesPolicy = this.state.showCookiesPolicy;

    return (
      <div>
        <div className="social-links-mobile">
          <div className="linked-in-logo" onClick={() => window.open('https://uk.linkedin.com/company/actifycic', '_blank')}></div>
          <div className="x-logo" onClick={() => window.open('https://twitter.com/Actify', '_blank')}></div>
        </div>
        <div id="footer" className="footer-container" style={paddingZero ? { paddingLeft: 0, marginBottom: 0 } : {}}>
          <div className="footer-bottom">
            <div className="copyright">
              Copyright Actify {new Date().getFullYear()}
            </div>
            <div className="footer-links">
              <Button className="link-button" onClick={() => this.setState({ showTerms: true })}>Terms and Conditions</Button>
              <Button className="link-button" onClick={() => this.setState({ showDataCollection: true })}>Privacy Policy</Button>
              <Button className="link-button" onClick={() => this.setState({ showCookiesPolicy: true })}>Cookies Policy</Button>
            </div>
            <div className="social-links" style={{ display: 'flex', gap: '10px' }}>
                <div className="linked-in-logo" onClick={() => window.open('https://uk.linkedin.com/company/actifycic', '_blank')}></div>
                <div className="x-logo" onClick={() => window.open('https://twitter.com/Actify', '_blank')}></div>
              </div>
          </div>

          <Modal show={showTerms} onHide={() => this.setState({ showTerms: false })}>
            <Modal.Header closeButton />
            <div className="terms-container">
              <div className="modal-header-container">
                <img
                  alt="actify"
                  className="img-fluid tc-image"
                  src={logo}
                />
                <h3>Terms and Conditions of Use</h3>
              </div>
              <div className="terms-content-container">
                {termsAndConditions}
              </div>

              <div className="back-button-container">
                <Button className="back-button" onClick={() => this.setState({ showTerms: false })}>
                  Close
                </Button>
              </div>
            </div>
          </Modal>

          <Modal show={showDataCollection} onHide={() => this.setState({ showDataCollection: false })}>
            <Modal.Header closeButton />
            <div className="terms-container">
              <div className="modal-header-container">
                <img
                  alt="actify"
                  className="img-fluid tc-image"
                  src={logo}
                />
                <h3>Privacy Policy</h3>
              </div>
              <div className="terms-content-container">
                {dataCollectionPolicy}
              </div>

              <div className="back-button-container">
                <Button className="back-button" onClick={() => this.setState({ showDataCollection: false })}>
                  Close
                </Button>
              </div>
            </div>
          </Modal>

          <Modal show={showCookiesPolicy} onHide={() => this.setState({ showCookiesPolicy: false })}>
            <Modal.Header closeButton />
            <div className="terms-container">
              <div className="modal-header-container">
                <img
                  alt="actify"
                  className="img-fluid tc-image"
                  src={logo}
                />
                <h3>Cookies Policy</h3>
              </div>
              <div className="terms-content-container">
                {cookiesPolicy}
              </div>

              <div className="back-button-container">
                <Button className="back-button" onClick={() => this.setState({ showCookiesPolicy: false })}>
                  Close
                </Button>
              </div>
            </div>
          </Modal>

        </div>
      </div>
    );
  }
}

export default Footer;
